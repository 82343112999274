import React from "react"

import Layout from "components/Layout/en"
import { Container, Row, Col } from "react-bootstrap"

import SupporterLogos from "components/OurPartnersSection/SupporterLogos"

// Import images
import teamGroupImg from "./moonteam_upper_body.jpg"

export default function(props) {
  const header = {
    text: "We Teach Machines to See the World",
    description:
      "Our MoonVision Toolbox enables domain experts around the world to better understand, automate and quality check the processes in their responsibilities.",
    image: teamGroupImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="py-5">
          <Col className="col-md-8 offset-md-2">
            <h2>Enabling Experts to solve future challenges today</h2>
            <br />
            <p>
              AI is the toolset for tomorrow’s workforce, allowing us to improve
              every aspect of our lives to solve the big challenges that lay in
              front of us. Machines will be able to see and understand their
              environment, to work more autonomously to our benefit. In this
              process the relationship between people and machines will be
              strengthened and will lead to more interactions between those
              partners.
            </p>
            <p>
              At MoonVision, we`re providing a toolset for domain experts to
              teach machines on visual inspection. We help them to provide
              better quality assurance and process reliability in their
              responsibilities to reduce the chance of error to zero. The best
              way to prepare tomorrow’s workforce is by giving them the tools to
              stay in control.
            </p>
          </Col>
        </Row>
      </Container>
      <SupporterLogos />
    </Layout>
  )
}
