import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

const SupporterLogos = () => (
  <StaticQuery
    query={graphql`
      {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: {
              eq: "components/OurPartnersSection/supporter_logos"
            }
          }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(grayscale: true, maxWidth: 130, quality: 100) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Container>
          <Row className="pt-5">
            <Col
              lg="8"
              className="d-flex flex-wrap align-content-around offset-md-2"
            >
              {data.allFile.edges.map(logo => (
                <div key={logo.node.id} className="flex-fill">
                  <img
                    key={logo.node.id}
                    alt={`${logo.node.name} logo`}
                    src={logo.node.childImageSharp.fluid.src}
                    style={{
                      maxWidth:
                        logo.node.childImageSharp.fluid.presentationWidth + 30,
                      // minWidth:
                      //   logo.node.childImageSharp.fluid.presentationWidth - 30,
                      margin: "1.5rem",
                    }}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      )
    }}
  />
)

export default SupporterLogos
